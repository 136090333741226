<footer
  itemtype="https://schema.org/WPFooter" target="_blank"
  itemscope="itemscope"
  id="colophon"
  role="contentinfo"
>
  <div class="footer-width-fixer">
    <div
      data-elementor-type="wp-post"
      data-elementor-id="7675"
      class="elementor elementor-7675"
      data-elementor-settings="[]"
    >
      <div class="elementor-inner">
        <div class="elementor-section-wrap">
          <section
            class="elementor-section elementor-top-section elementor-element elementor-element-91c455c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="91c455c"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-row">
                <div
                  class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e398ab1"
                  data-id="e398ab1"
                  data-element_type="column"
                >
                  <div
                    class="elementor-column-wrap elementor-element-populated"
                  >
                    <div class="elementor-widget-wrap">
                      <section
                        class="elementor-section elementor-inner-section elementor-element elementor-element-7ea4fe1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="7ea4fe1"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div class="elementor-row">
                            <div
                              class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3b8e3d4"
                              data-id="3b8e3d4"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-column-wrap elementor-element-populated"
                              >
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-1f6f4ab elementor-widget elementor-widget-heading"
                                    data-id="1f6f4ab"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <h2
                                        class="elementor-heading-title elementor-size-default"
                                      >
                                        Menu
                                      </h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-449962c elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                    data-id="449962c"
                                    data-element_type="widget"
                                    data-widget_type="icon-list.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/"
                                            target="_blank" >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Accueil</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/reservez-votre-sejour-2/"
                                            target="_blank" >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Réservations</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/espace-professionnel-2/"
                                            target="_blank"  >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Services / Packs</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/nos-secteurs-2/"
                                            target="_blank"   >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Secteurs</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/qui-sommes-nous-2/"
                                            target="_blank"    >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Qui sommes-nous</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/contact-3/"
                                            target="_blank"  >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Contact</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/cgv/"
                                            target="_blank"    >
                                            <span
                                              class="elementor-icon-list-text"
                                              >CGV</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/conditions-generales-utilisation/"
                                            target="_blank"  >
                                            <span
                                              class="elementor-icon-list-text"
                                              >GGU</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/politiques-de-confidentialite/"
                                            target="_blank"   >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Politique de
                                              confidentialité</span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://rentatranquille.com/mentions-legales/"
                                            target="_blank"  >
                                            <span
                                              class="elementor-icon-list-text"
                                              >Mentions légales</span
                                            >
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-69d2226"
                              data-id="69d2226"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-column-wrap elementor-element-populated"
                              >
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-3074743 elementor-widget elementor-widget-heading"
                                    data-id="3074743"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <h2
                                        class="elementor-heading-title elementor-size-default"
                                      >
                                        CONTACT
                                      </h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-95c7519 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                    data-id="95c7519"
                                    data-element_type="widget"
                                    data-widget_type="icon-list.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <ul class="elementor-icon-list-items">
                                        <li class="elementor-icon-list-item">
                                          <span
                                            class="elementor-icon-list-icon"
                                          >
                                            <i
                                              aria-hidden="true"
                                              class="fas fa-map-marker"
                                            ></i>
                                          </span>
                                          <span class="elementor-icon-list-text"
                                            >30 Chemin du Mas Bonique, 66330
                                            Cabestany, France</span
                                          >
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <span
                                            class="elementor-icon-list-icon"
                                          >
                                            <i
                                              aria-hidden="true"
                                              class="fas fa-envelope"
                                            ></i>
                                          </span>
                                          <span class="elementor-icon-list-text"
                                            >contact@rentatranquille.com</span
                                          >
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://www.facebook.com/Rentatranquille"
                                            target="_blank" >
                                            <span
                                              class="elementor-icon-list-icon"
                                            >
                                              <i
                                                aria-hidden="true"
                                                class="fab fa-facebook-square"
                                              ></i>
                                            </span>
                                            <span
                                              class="elementor-icon-list-text"
                                              ><b>Rentatranquille</b></span
                                            >
                                          </a>
                                        </li>
                                        <li class="elementor-icon-list-item">
                                          <a
                                            href="https://www.instagram.com/rentatranquille/"
                                            target="_blank" >
                                            <span
                                              class="elementor-icon-list-icon"
                                            >
                                              <i
                                                aria-hidden="true"
                                                class="fab fa-instagram"
                                              ></i>
                                            </span>
                                            <span
                                              class="elementor-icon-list-text"
                                              ><b>Rentatranquille</b></span
                                            >
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-41af83c"
                              data-id="41af83c"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-column-wrap elementor-element-populated"
                              >
                                <div class="elementor-widget-wrap">
                                  <div
                                    class="elementor-element elementor-element-8ccc4ab elementor-widget elementor-widget-heading"
                                    data-id="8ccc4ab"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <h2
                                        class="elementor-heading-title elementor-size-default"
                                      >
                                        Recommandé sur
                                      </h2>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-c8c51a0 elementor-widget elementor-widget-image"
                                    data-id="c8c51a0"
                                    data-element_type="widget"
                                    data-widget_type="image.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <div class="elementor-image">
                                        <noscript
                                          ><img
                                            width="517"
                                            height="81"
                                            src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?fit=517%2C81&amp;ssl=1"
                                            class="attachment-large size-large"
                                            target="_blank"
                                            alt=""
                                            srcset="
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?w=517&amp;ssl=1           517w,
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?resize=300%2C47&amp;ssl=1 300w
                                            "
                                            sizes="(max-width: 517px) 100vw, 517px" /></noscript
                                        ><img
                                          width="517"
                                          height="81"
                                          src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?fit=517%2C81&amp;ssl=1"
                                          data-src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?fit=517%2C81&amp;ssl=1"
                                          class="attachment-large size-large lazyloaded"
                                          alt=""
                                          data-srcset="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?w=517&amp;ssl=1 517w, https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?resize=300%2C47&amp;ssl=1 300w"
                                          data-sizes="(max-width: 517px) 100vw, 517px"
                                          sizes="(max-width: 517px) 100vw, 517px"
                                          srcset="
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?w=517&amp;ssl=1           517w,
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2020/12/footer-banner-transparant.png?resize=300%2C47&amp;ssl=1 300w
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-eb2656c elementor-widget elementor-widget-image"
                                    data-id="eb2656c"
                                    data-element_type="widget"
                                    data-widget_type="image.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <div class="elementor-image">
                                        <noscript
                                          ><img
                                            width="936"
                                            height="576"
                                            src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?fit=936%2C576&amp;ssl=1"
                                            class="attachment-full size-full"
                                            alt=""
                                            srcset="
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?w=936&amp;ssl=1            936w,
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=300%2C185&amp;ssl=1 300w,
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=768%2C473&amp;ssl=1 768w,
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=18%2C12&amp;ssl=1    18w,
                                              https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=600%2C369&amp;ssl=1 600w
                                            "
                                            sizes="(max-width: 936px) 100vw, 936px" /></noscript
                                        ><img
                                          width="936"
                                          height="576"
                                          src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?fit=936%2C576&amp;ssl=1"
                                          data-src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?fit=936%2C576&amp;ssl=1"
                                          class="attachment-full size-full lazyloaded"
                                          alt=""
                                          data-srcset="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?w=936&amp;ssl=1 936w, https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=300%2C185&amp;ssl=1 300w, https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=768%2C473&amp;ssl=1 768w, https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=18%2C12&amp;ssl=1 18w, https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=600%2C369&amp;ssl=1 600w"
                                          data-sizes="(max-width: 936px) 100vw, 936px"
                                          sizes="(max-width: 936px) 100vw, 936px"
                                          srcset="
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?w=936&amp;ssl=1            936w,
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=300%2C185&amp;ssl=1 300w,
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=768%2C473&amp;ssl=1 768w,
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=18%2C12&amp;ssl=1    18w,
                                            https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/kisspng-coinbase-cryptocurrency-exchange-bitcoin-litecoin-en-Iyi-coin-borsalari-hangileridir-altcoinci-5b62b60d347132.7398317015331957892148.png?resize=600%2C369&amp;ssl=1 600w
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="elementor-element elementor-element-86cd081 elementor-widget elementor-widget-image"
                                    data-id="86cd081"
                                    data-element_type="widget"
                                    data-widget_type="image.default"
                                  >
                                    <div class="elementor-widget-container">
                                      <div class="elementor-image">
                                        <noscript
                                          ><img
                                            src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/logo-stripe.png?fit=450%2C150&amp;ssl=1"
                                            title="logo-stripe"
                                            alt="logo-stripe" /></noscript
                                        ><img
                                          class="lazyloaded"
                                          src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/logo-stripe.png?fit=450%2C150&amp;ssl=1"
                                          data-src="https://i0.wp.com/rentatranquille.com/site/wp-content/uploads/2021/06/logo-stripe.png?fit=450%2C150&amp;ssl=1"
                                          title="logo-stripe"
                                          alt="logo-stripe"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</footer>
