<footer class="mkdf-page-footer mkdf-footer-skin-light">
  <div class="mkdf-footer-top-holder">
    <div class="mkdf-footer-top-inner mkdf-full-width">
      <div class="mkdf-grid-row mkdf-footer-top-alignment-left">
        <div class="mkdf-column-content mkdf-grid-col-3">
          <div
            id="media_image-2"
            class="widget mkdf-footer-column-1 widget_media_image"
          >
            <a href="https://www.guest-adom.com/"
              ><img
                width="652"
                height="132"
                src="https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473.png"
                class="image wp-image-12700 attachment-full size-full entered lazyloaded"
                alt="GUEST ADOM LOGO BLANC"
                style="max-width: 100%; height: auto"
                data-lazy-srcset="https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473.png 652w, https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473-300x61.png 300w"
                data-lazy-sizes="(max-width: 652px) 100vw, 652px"
                data-lazy-src="https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473.png"
                data-ll-status="loaded"
                sizes="(max-width: 652px) 100vw, 652px"
                srcset="
                  https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473.png        652w,
                  https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473-300x61.png 300w
                " /><noscript
                ><img
                  width="652"
                  height="132"
                  src="https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473.png"
                  class="image wp-image-12700 attachment-full size-full"
                  alt="GUEST ADOM LOGO BLANC"
                  style="max-width: 100%; height: auto"
                  srcset="
                    https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473.png        652w,
                    https://www.guest-adom.com/wp-content/uploads/2021/06/LOGO-GA-RVB-2021-blanc-horizontal-e1632478521473-300x61.png 300w
                  "
                  sizes="(max-width: 652px) 100vw, 652px" /></noscript
            ></a>
          </div>
          <div id="text-2" class="widget mkdf-footer-column-1 widget_text">
            <div class="textwidget">
              <div style="margin-top: -20px">
                <div class="font-change" style="margin-top: -8px">
                  GUEST ADOM est une conciergerie de proximité qui offre un
                  service de gestion complète de logement en location de courte
                  durée ou de moyenne durée sur des plateformes telles que
                  Airbnb, Abritel, Booking.com. De la création de l’annonce
                  jusqu’à l’accueil du locataire en passant par le ménage et la
                  gestion du linge, et en maximisant vos revenus. L’ensemble des
                  prestations sont internalisées ce qui rend le process GUEST
                  ADOM unique et fiable.
                </div>
              </div>
              <div style="margin-top: 29px"></div>
            </div>
          </div>
        </div>
        <div class="mkdf-column-content mkdf-grid-col-3">
          <div id="text-3" class="widget mkdf-footer-column-2 widget_text">
            <div class="textwidget">
              <div
                style="
                  margin-top: 80px;
                  color: #fff;
                  font-size: 18px;
                  letter-spacing: 0;
                  font-weight: bold;
                  font-family: Nunito-Light, sans-serif;
                  margin-top: 80px;
                  color: #fff;
                  font-size: 18px;
                  letter-spacing: 0;
                  font-weight: bold;
                  font-family: Nunito-Light, sans-serif;
                "
              >
                Informations de contact
              </div>
            </div>
          </div>
          <a
            class="mkdf-icon-widget-holder"
            href="https://www.google.com/search?q=guestadomlaval&amp;rlz=1C1GCEA_enFR949FR949&amp;oq=guest+adom+laval&amp;aqs=chrome..69i57j69i61.2048j0j7&amp;sourceid=chrome&amp;ie=UTF-8&amp;tbs=lf:1,lf_ui:14&amp;tbm=lcl&amp;sxsrf=AOaemvLkaxde6sWX7lnLmKLgJnd5_oAclA:1631632194354&amp;rflfq=1&amp;num=10&amp;rldimm=133095233923618481&amp;lqi=ChBndWVzdCBhZG9tIGxhdmFsWhIiEGd1ZXN0IGFkb20gbGF2YWySARtwcm9wZXJ0eV9tYW5hZ2VtZW50X2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTTJjM0EyU1dWQkVBRaoBEhABKg4iCmd1ZXN0IGFkb20oBA&amp;ved=2ahUKEwiWpuTK3_7yAhVOqxoKHXowBmoQvS56BAgMEDs&amp;rlst=f#rlfi=hd:;si:133095233923618481,l,ChBndWVzdCBhZG9tIGxhdmFsWhIiEGd1ZXN0IGFkb20gbGF2YWySARtwcm9wZXJ0eV9tYW5hZ2VtZW50X2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTTJjM0EyU1dWQkVBRaoBEhABKg4iCmd1ZXN0IGFkb20oBA;mv:[[48.9513822,2.6567132],[47.024532,-3.0360047999999997]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:14https://www.google.com/search?q=guestadomlaval&amp;rlz=1C1GCEA_enFR949FR949&amp;oq=guest+adom+laval&amp;aqs=chrome..69i57j69i61.2048j0j7&amp;sourceid=chrome&amp;ie=UTF-8&amp;tbs=lf:1,lf_ui:14&amp;tbm=lcl&amp;sxsrf=AOaemvLkaxde6sWX7lnLmKLgJnd5_oAclA:1631632194354&amp;rflfq=1&amp;num=10&amp;rldimm=133095233923618481&amp;lqi=ChBndWVzdCBhZG9tIGxhdmFsWhIiEGd1ZXN0IGFkb20gbGF2YWySARtwcm9wZXJ0eV9tYW5hZ2VtZW50X2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTTJjM0EyU1dWQkVBRaoBEhABKg4iCmd1ZXN0IGFkb20oBA&amp;ved=2ahUKEwiWpuTK3_7yAhVOqxoKHXowBmoQvS56BAgMEDs&amp;rlst=f#rlfi=hd:;si:133095233923618481,l,ChBndWVzdCBhZG9tIGxhdmFsWhIiEGd1ZXN0IGFkb20gbGF2YWySARtwcm9wZXJ0eV9tYW5hZ2VtZW50X2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTTJjM0EyU1dWQkVBRaoBEhABKg4iCmd1ZXN0IGFkb20oBA;mv:[[48.9513822,2.6567132],[47.024532,-3.0360047999999997]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:14"
            target="_blank"
          >
            <!-- <span
              class="mkdf-icon-element icon_question"
              style="font-size: 16px"
            ></span> -->

            <span
              class="mkdf-icon-text"
              style="font-size: 16px; font-family: Nunito, sans-serif"
              >6 impasse des Tailleurs, 53810 Changé</span
            >
          </a>
          <div class="widget mkdf-separator-widget">
            <div
              class="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal"
            >
              <div
                class="mkdf-separator"
                style="
                  border-style: solid;
                  border-bottom-width: 10px;
                  margin-top: 0px;
                  margin-bottom: 0px;
                "
              ></div>
            </div>
          </div>
          <a
            class="mkdf-icon-widget-holder"
            href="mailto:contact@guest-adom.com"
            target="_blank"
          >
            <!-- <i class="far fa-envelope"></i> -->
            <!-- <span
              class="mkdf-icon-element icon_mail_alt"
              style="font-size: 16px"
            ></span> -->
            <span class="mkdf-icon-text" style="font-size: 16px"
              >contact@guest-adom.com</span
            >
          </a>
          <div class="widget mkdf-separator-widget">
            <div
              class="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal"
            >
              <div
                class="mkdf-separator"
                style="
                  border-style: solid;
                  border-bottom-width: 10px;
                  margin-top: 0px;
                  margin-bottom: 0px;
                "
              ></div>
            </div>
          </div>
          <a
            class="mkdf-icon-widget-holder"
            href="tel:+33%20(2)%2043%2049%2056%2014"
            target="_blank"
          >
            <!-- <i class="far fa-phone-alt"></i> -->
            <!-- <span class="mkdf-icon-element icon_pin_alt"></span> -->
            <span class="mkdf-icon-text" style="font-size: 16px"
              >02 43 49 56 14</span
            >
          </a>
          <div class="widget mkdf-separator-widget">
            <div
              class="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal"
            >
              <div
                class="mkdf-separator"
                style="border-style: solid; border-bottom-width: 10px"
              ></div>
            </div>
          </div>
          <div
            class="widget mkdf-social-icons-group-widget mkdf-light-skin mkdf-circle-icons text-align-left"
          >
            <a
              class="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#f18803"
              style="color: #233361; font-size: 19px; margin: 0 14px 0 0"
              href="https://www.instagram.com/guestadom/?hl=fr"
              target="_blank"
            >
            <img class="mkdf-social-icon-widget img-width" src="assets/icon/instagram-dark-bleu.svg" >
            </a>
            <a
              class="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#f18803"
              style="color: #233361; font-size: 19px; margin: 0 14px 0 0"
              href="https://www.facebook.com/guestadom/?modal=admin_todo_tour"
              target="_blank"
            >
            <img class="mkdf-social-icon-widget img-width" src="assets/icon/facebook-dark-bleu.svg" >
           
            </a>
            <a
              class="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#f18803"
              style="color: #233361; font-size: 19px; margin: 0 14px 0 0"
              href="https://www.linkedin.com/company/guest-adom/?viewAsMember=true"
              target="_blank"
            >
            <img class="mkdf-social-icon-widget img-width" src="assets/icon/linkedin-dark-bleu.svg" >
            </a>
          </div>
        </div>
        <div class="mkdf-column-content mkdf-grid-col-3">
          <div id="text-27" class="widget mkdf-footer-column-3 widget_text">
            <div class="textwidget">
              <div
                style="
                  margin-top: 80px;
                  color: #fff;
                  font-size: 18px;
                  letter-spacing: 0;
                  font-weight: bold;
                  font-family: Nunito-Light, sans-serif;

                  line-height: 28px;

                  overflow-x: hidden !important;
                  -webkit-font-smoothing: antialiased;
                "
              >
                Tout savoir sur GUEST ADOM
              </div>
            </div>
          </div>
          <div id="text-28" class="widget mkdf-footer-column-3 widget_text">
            <div class="textwidget">
              <p>
                <a
                  href="https://www.guest-adom.com/conciergerie-airbnb-tarifs/"
                  target="_blank"
                  rel="noopener"
                  >Tarifs</a
                ><br />
                <a
                  href="https://www.guest-adom.com/qui-sommes-nous/"
                  target="_blank"
                  rel="noopener"
                  >Qui sommes-nous ?</a
                ><br />
                <a
                  href="https://guest-adom.welcomekit.co/"
                  target="_blank"
                  rel="noopener"
                  >Espace recrutement</a
                ><br />
                <a href="https://www.guest-adom.com/nos-partenaires/"
                  >Nos partenaires</a
                ><br />
                <a
                  href="https://www.guest-adom.com/parrainage/"
                  target="_blank"
                  rel="noopener"
                  >Parrainez un ami</a
                ><br />
                <a
                  href="https://www.guest-adom.com/politique-de-confidentialite/"
                  target="_blank"
                  rel="noopener"
                  >Confidentialité</a
                ><br />
                <a
                  href="https://www.guest-adom.com/conciergerie-airbnb-mentions-legales/"
                  >Mentions légales</a
                >
              </p>
            </div>
          </div>
          <div class="widget mkdf-separator-widget">
            <div
              class="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal"
            >
              <div
                class="mkdf-separator"
                style="
                  border-style: solid;
                  border-bottom-width: 5px;
                  margin-top: 0px;
                  margin-bottom: 0px;
                "
              ></div>
            </div>
          </div>
        </div>
        <div class="mkdf-column-content mkdf-grid-col-3">
          <div id="text-4" class="widget mkdf-footer-column-4 widget_text">
            <div class="textwidget">
              <div
                style="
                  margin-top: 80px;
                  color: #fff;
                  font-size: 18px;
                  letter-spacing: 0;
                  font-weight: bold;

                  margin-top: 80px;
                  color: #fff;
                  font-size: 18px;
                  letter-spacing: 0;
                  font-weight: bold;
                  font-family: Nunito-Light, sans-serif;
                  margin-top: 80px;
                "
              >
                Besoin de plus d’informations ?
              </div>
              <div style="margin-top: 5px">
                <p>
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-blog/"
                    target="_blank"
                    rel="noopener"
                    >Blog</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/presse/"
                    target="_blank"
                    rel="noopener"
                    >Presse</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div id="text-33" class="widget mkdf-footer-column-4 widget_text">
            <div class="textwidget">
              <div
                style="
                  margin-top: 0px;
                  color: #fff;
                  font-size: 18px;
                  letter-spacing: 0;
                  font-weight: bold;
                "
              >
                Où nous trouver ?
              </div>
              <div style="margin-top: 5px">
                <p>
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-paris/"
                    >Votre conciergerie Airbnb à Paris</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-la-baule-escoublac/"
                    >Votre conciergerie Airbnb à La Baule</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-pornic/"
                    >Votre conciergerie Airbnb à Pornic</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-laval/"
                    >Votre conciergerie Airbnb à Laval</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-vannes/"
                    >Votre conciergerie Airbnb à Vannes</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-saint-malo/"
                    >Votre conciergerie Airbnb à Saint-Malo</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-arzon/"
                    >Votre conciergerie Airbnb à Arzon</a
                  ><br />
                  <a
                    href="https://www.guest-adom.com/conciergerie-airbnb-Challans/"
                    target="_blank"
                    rel="noopener"
                    >Votre conciergerie Airbnb à Challans</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mkdf-footer-bottom-holder">
      <div class="mkdf-footer-bottom-inner mkdf-full-width">
        <div class="mkdf-grid-row">
          <div class="mkdf-grid-col-12">
            <div
              id="text-5"
              class="widget mkdf-footer-bottom-column-1 widget_text"
            >
              <div class="textwidget">
                <div></div>
                <div
                  style="
                    margin-top: -11px;
                    color: #fff;
                    font-size: 18px;
                    letter-spacing: 0;
                    font-weight: bold;
                  "
                >
                  Copyright © 2021 GUEST ADOM. Tous droits réservés
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
